@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap');

*::selection {
  background-color: rgba(255, 255, 255, 0);
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  margin: 0;

  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  font-style: normal;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #202020;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#canvas-placeholder {
  pointer-events: none;
  position: absolute;
  display: flex;
  justify-content: space-between;
}

#canvas-placeholder pre {
  display: flex;
  left: 0;
  top: 0;
  margin: 0;
  opacity: 0.5;
  color: white;
  font-size: 10vw;
  /* transition: 0.01s all ease-in-out; */
  cursor: default;
}

#canvas-placeholder pre#l-brace {
  align-items: start;
}

#canvas-placeholder pre#r-brace {
  align-items: end;
}

#author-title {
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
  right: 0;
  bottom: 0;
  margin:  0;
  color: #fff;
  opacity: 0.1;  
  font-size: 0.7em;
  padding: 10px;
}